import axios from "axios";
import BaseService from "./BaseService";

class OrderItem extends BaseService{
    
    async fetchOrderItem(id){
        return await axios.get(`${OrderItem.BASE_URL}/api/v1/orderitem/section/${id}`,
            {
            // "Access-Control-Allow-Origin": true,
            headers: {'Content-Type': 'application/json',
             Authorization: 'Bearer '+OrderItem.TOKEN}
            });
    }
    /*async fetchUsers() {
        try {
            this.setState({...this.state, isFetching: true});
            const response = await axios.get(USER_SERVICE_URL);
            this.setState({users: response.data, isFetching: false});
        } catch (e) {
            console.log(e);
            this.setState({...this.state, isFetching: false});
        }
    }*/
}

export default OrderItem;