import React, { Component } from 'react'
import axios from "axios";
import './AdBanner.css';
import OrderItem from '../services/OrderItem';
import AdMetric from '../services/AdMetric';

class AdBanner extends Component {
    constructor(props) {
        super(props);
      console.log('entro al constructor');

        this.state = {
            banners: [],
            randomLis: [],
            placementId: props.placementId,
            isFetching: false

        };

        
     }

     componentDidMount() {
      if(this.state.isFetching!==true){
          this.fetchBanners(0);
      }

     }

    fetchBanners(pId){
      this.setState({...this.state, isFetching: true});
      let oi = new OrderItem();
        oi.fetchOrderItem(pId===0?this.state.placementId:pId)
        .then((response) => {
                    const someArray = response.data.keys();
                   // someArray.sort(() => Math.random() - 0.5);
                    this.setState({...this.state, banners: response.data, randomLis: someArray, isFetching: false});
              });
      }


    getRandomObject(elem){
      const min = 0;
      const max = elem.length -1;
      const rand = min + Math.random() * (max - min);
      return elem[rand]
    }
    
    handleClick(data) {
      console.log('Se hizo click',data);
      
      let metric = new AdMetric();
      metric.registerClick(data.id,1).then((response) => {});
      //this.setState({placementsSalected: id});

    }

    handleImageLoaded(data) {
      console.log('Se cargo la imagen '+data.id );
      let metric = new AdMetric();
      metric.registerView(data.id,1).then((response) => {});
      //this.setState({ imageStatus: "loaded" });
    }
  

    componentDidUpdate(prevProps, prevState) {
      console.log('prev', prevProps);
      console.log('props', this.props);
      console.log('prevState', prevState);
      if (prevProps.placementId !== this.props.placementId) {
          this.setState({
            banners: [],
            randomLis: [],
            placementId: this.props.placementId,
            isFetching: false
        });
          this.fetchBanners(this.props.placementId);
      }
    }

    render() {
     
      return this.state.randomLis==[]?(''):Array.from(this.state.randomLis).map(
        (el) => 
          (
            <div key={this.state.banners[el].id} className='bgcolor'>
                <p className='txtcontent'>Banner {this.state.banners[el].id}</p>
                <a href={this.state.banners[el].linkTo} onClick={(e)=>{e.preventDefault();this.handleClick(this.state.banners[el])}}>
                    <img width="100%" src={this.state.banners[el].imageUrl} alt={this.state.banners[el].title} onLoad={()=>this.handleImageLoaded(this.state.banners[el])}/>
                </a>
            </div>
          )
        
      );
      
    }
}

export default AdBanner;