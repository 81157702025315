import logo from './logo.svg';
import './App.css';
import  AdBanner  from './components/AdBanner';
import axios from "axios";
import React, { Component } from 'react';
import HomeHeader from './components/HomeHeader';

class App extends Component{

  constructor(props){
    super(props);
    this.state = {
      sectionId: null
    }

  }
  // componentDidMount() {
  //   this.fetchUsers();
  // }

  // fetchUsersWithFetchAPI = () => {
  //   this.setState({...this.state, isFetching: true});
  //   fetch(USER_SERVICE_URL)
  //       .then(response => response.json())
  //       .then(result => {
  //           this.setState({users: result, isFetching: false});
  //       })
  //       .catch(e => {
  //           console.log(e);
  //           this.setState({...this.state, isFetching: false});
  //       });
  // };

  // fetchUsers = this.fetchUsersWithFetchAPI;

  handlerHomeChange(sectionId){
    console.log(sectionId);
    this.setState({sectionId: sectionId});
  }

  render(){
    return (
        <div className="App">
          <HomeHeader handlerChangSection={(data)=>this.handlerHomeChange(data)}/>
          {this.state.sectionId!==null && (<AdBanner placementId={this.state.sectionId}/>)}
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Mock Ad Server {this.state.sectionId}</p>
          </header>
        </div>
      )
    };

}

export default App;
