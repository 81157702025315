import axios from "axios";

class BaseService{
    //static BASE_URL = 'http://localhost:8000';
    static BASE_URL = 'https://api-labs-test.neuraverso.com';
    static TOKEN = null;

    constructor() {
        this.fetchToken();
    }

    fetchToken(){

        let token = localStorage.getItem("token");
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        const clientId = '9734748d-3d7f-4204-84f1-934a45e527aa';
        const clientSecret = 'f4YKnO8WeCiQ7mGsppr4w8AgVaXREfrtqvmx1VWq';
        const grantType = 'client_credentials';
        if(BaseService.TOKEN!=null) return;
        if(token!=null && BaseService.TOKEN==null){
            BaseService.TOKEN = token;
            return;
        }
        
        return axios.post(BaseService.BASE_URL + '/oauth/token',{
            client_id:clientId,
            client_secret: clientSecret,
            grant_type: grantType
          },
          {
           'Content-Type': 'application/json'
          })
          .then((response)=>{
            this.TOKEN = response.data.access_token;
            localStorage.setItem('token',this.TOKEN);
          });
    }

}

export default BaseService;