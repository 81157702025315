import axios from "axios";
import BaseService from "./BaseService";

class AdMetric extends BaseService{
    
    async registerView(id,step=1){
        return await axios.post(`${AdMetric.BASE_URL}/api/v1/metric`,
            {
                adOrderItemId: id,
                clicks: 0,
                impressions: 1*step,
                ctr: 0,
                userData: '{}',
                browser: '{}'
            },
            {
            // "Access-Control-Allow-Origin": true,
            headers: {'Content-Type': 'application/json',
             Authorization: 'Bearer '+AdMetric.TOKEN}
            });
    }

    async registerClick(id,step=1){
        return await axios.post(`${AdMetric.BASE_URL}/api/v1/metric`,
            {
                adOrderItemId: id,
                clicks: 1*step,
                impressions: 0,
                ctr: 0,
                userData: '{}',
                browser: '{}'
            },
            {
            // "Access-Control-Allow-Origin": true,
            headers: {'Content-Type': 'application/json',
             Authorization: 'Bearer '+AdMetric.TOKEN}
            });
    }
    /*async fetchUsers() {
        try {
            this.setState({...this.state, isFetching: true});
            const response = await axios.get(USER_SERVICE_URL);
            this.setState({users: response.data, isFetching: false});
        } catch (e) {
            console.log(e);
            this.setState({...this.state, isFetching: false});
        }
    }*/
}

export default AdMetric;