import React, { Component } from 'react'
import './HomeHeader.css'
import Placement from '../services/Placement';

class HomeHeader extends Component {
    constructor(props) {
        super(props);
        console.log('prop', props);
        this.state = {
            placementsSalected: '',
            placements: [],
            isFetching: false
        };
    }

    componentDidMount() {
        console.log('Estoy en didMount', this.state);

        if(this.state.isFetching!==true){
            this.fetchPlacement();
        }

    }

   fetchPlacement(){
        this.setState({...this.state, isFetching: true});
        let pl = new Placement();
        pl.fetchPlacement().then( (response) => {
            this.setState({placements: response.data ,placementsSalected: response.data[0] ,isFetching: false});
            this.props.handlerChangSection(response.data[0].id);
        })
    }

    handleClick(data) {
      console.log('Se hizo click',data);

      this.setState({...this.state,placementsSalected: data});
      this.props.handlerChangSection(data.id);

    }
    render() {
      return this.state.placements!=[]?(
      <header className="header">
        <div className="nav-menu">
            <ul className="header-options">
                { this.state.placements?.map((d, idx) => {
                    return (<li className={d.id===this.state.placementsSalected.id?'selected':''} key={d.id}><a onClick={() => this.handleClick(d)}>{d.name}</a></li>)
                })}
            </ul>
        </div>
    </header>):('')
    }
}

export default HomeHeader;